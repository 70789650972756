
import LIST_WITH_FIRST_PTAG from "../extended_class/listWithFirstPtag";

const [w, d] = [window, document];


const domReady = () => {
  // new LIST_WITH_FIRST_PTAG('facility');
}


export default function FACILITY () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}