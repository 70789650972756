import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";
const [ w, d ] = [window, document];

class Blocks {
  constructor() {
    this.init();
  }

  init() {
    this.headline();
    this.program('.block-program__list[data-plugin="getArticles"]');
    this.news();
  }

  mainvisual() {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="slider"]',{
      loop: true,
      effect: 'fade',
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 5000,
        disableOnInteraction: false,
        waitForTransition: false
      },
    });
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.block-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item, content) => `
      <h3 class="block-headline__title"><span>お知らせ</span></h3>
      <a href="${item.href}" class="block-headline__link">
        <span class="date">${item.date}</span><span class="line"> | </span></span><span class="cate">${item.category.name}</span>
        <h3 class="subject ${item.is_new ? 'is-new' : ''}">${item.title.str_truncate(15)}</h3>
        <i class="fa-solid fa-arrow-right icon"></i>
      </a>`;
    getArticle.render();
  }

  program(selector)
  {
    const works_block = new GET_ARTICLES(selector);
    works_block.makeItem = (item,content) => `
    <li class="block-${content}__item">
        <a href="${item.href}" class="block-${content}__item-link">
          <div class="block-${content}__item-img">
            <img src="${item.thumb}" alt="${item.title}">
          </div>
          <!-- /.block-${content}__item-img -->
          <div class="block-${content}__item-body">
            <span class="subject">${item.title}</span>
          </div>
          <!-- /.block-${content}__item-body -->
        </a>
        <!-- /.block-${content}__item-link -->
      </li>
      <!-- /.block-${content}__item -->
    `;
    // works_block.makeItem = (item) => console.log(item);
    works_block.render();
  }

  news() {
    const getArticle = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
    <a href="${item.href}" class="block-news-item">
      <figure class="block-news-item__thumb">
        <img src="${item.thumb}" alt="${item.title}" class="block-news-item__img" width="118" height="118" loading="lazy">
      </figure>
      <!-- /.block-news-item__thumb -->
      <div class="block-news-item__body">
        <span class="date-cate">
          <span class="date">${item.date}</span> | ${item.category ? `<span class="category">${item.category.name}</span>` : ''}
        </span>
        <!-- /.date-cate -->
        <span class="title${item.is_new? ' is-new':''}">${item.title.str_truncate(24)}</span>
      </div>
      <!-- /.block-news-item__body -->
    </a>`;
    getArticle.render();
  }
}

const domReady = TPL => {
  let blocks = new Blocks;
  w.addEventListener('load', () => {
    blocks.mainvisual();
    blocks.mainCatch();
  });
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}