const [w, d] = [window, document];

export default class LIST_WITH_FIRST_PTAG {
  constructor(content) {
    this.init(content)
  }

  init(content) {
    this.getArticles({ content }).then(res => this.apply(res));
  }

  apply(articles) {
    const items = d.querySelectorAll('[data-plugin="getFirstPtag"]');
    items.forEach(item => {
      const id = item.dataset.article;
      const this_article = articles.filter( v => v.id == id );
      const firstPtag = this.getFirstPtag(this_article);
      if( firstPtag ) item.innerHTML = firstPtag.innerHTML;
    });
  }

  getFirstPtag ([article]) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(article.body, 'text/html');
    return doc.querySelector('p');
  }

  getArticles ( params = {} ) {
    params = Object.assign({
      content : false,
      post_per_page : -1,
      post_status : 'publish',
      post_id : '',
      category_in : '',
      sort : 'DESC',
      exclude : ''
    }, params );

    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(params) }`, { method: 'GET'})
    .then( res => {
      if( !res.ok ) throw new Error(res.statusText);
      else return res.json();
    });
  }
}